// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

import "./CustomCursor.css";
;

function CustumCursor$CustomCursor(Props) {
  return React.createElement("div", {
              className: "cursorContainer"
            }, React.createElement("div", {
                  id: "custom-cursor"
                }));
}

var CustomCursor = {
  make: CustumCursor$CustomCursor
};

export {
  CustomCursor ,
}
/*  Not a pure module */
