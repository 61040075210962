// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Main from "./main/Main.res.js";
import * as React from "react";
import * as CustumCursor from "./cursor/CustumCursor.res.js";

import "./App.css";

const handleCursor = (event) => {
	document.getElementById("custom-cursor")
		.setAttribute("style", "top: "+(event.pageY - 0)+"px; left: "
			+(event.pageX - 0)+"px;")
};
;

function App$App(Props) {
  return React.createElement("div", {
              className: "appContainer",
              onMouseMove: handleCursor
            }, React.createElement(CustumCursor.CustomCursor.make, {}), React.createElement(Main.Main.make, {}));
}

var App = {
  make: App$App
};

export {
  App ,
}
/*  Not a pure module */
